import React from "react";
import styled from "styled-components";
import Colors from "../Constants/Colors";
import BorderRadius from "../Constants/BorderRadius";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${Colors.Accent2};
  color: ${Colors.Depth1};
  border: none;
  outline: none;
  padding: 8px 16px;
  font-weight: 500;
  border-radius: ${BorderRadius.Small};
  cursor: pointer;
  font-family: "Jost";

  &:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    transition-duration: 0.05s;
  }
`;

const Icon = styled.span`
  margin-right: 8px;
`;

interface ButtonProps {
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ onClick, label, icon, style }) => {
  return (
    <StyledButton onClick={onClick} style={style}>
      {icon && <Icon>{icon}</Icon>}
      {label}
    </StyledButton>
  );
};

export default Button;
