import React from "react";
import styled from "styled-components";

interface WidthBasedContainerProps {
  width: string;
  desktopWidth?: string;
  children: React.ReactNode;
}

const StyledWidthBasedContainer = styled.div<WidthBasedContainerProps>`
  width: ${(props) => props.width};
  @media (min-width: 768px) {
    padding: 2rem;
    width: ${(props) => props.desktopWidth || props.width};
  }
`;

const StyledWidthBasedContainerEdge = styled.div`
  border-radius: 1rem;
  border: 0.3rem solid transparent;
  backdrop-filter: blur(2px);
`;

const WidthBasedContainer: React.FC<WidthBasedContainerProps> = ({
  width,
  desktopWidth,
  children,
}) => {
  return (
    // I know this looks a bit stupid but it works :-) it blurs the container with a fallof on the blur towards the edges
    <StyledWidthBasedContainerEdge>
      <StyledWidthBasedContainerEdge>
        <StyledWidthBasedContainerEdge>
          <StyledWidthBasedContainerEdge>
            <StyledWidthBasedContainerEdge>
              <StyledWidthBasedContainerEdge>
                <StyledWidthBasedContainerEdge>
                  <StyledWidthBasedContainerEdge>
                    <StyledWidthBasedContainer
                      width={width}
                      desktopWidth={desktopWidth}
                    >
                      {children}
                    </StyledWidthBasedContainer>
                  </StyledWidthBasedContainerEdge>
                </StyledWidthBasedContainerEdge>
              </StyledWidthBasedContainerEdge>
            </StyledWidthBasedContainerEdge>
          </StyledWidthBasedContainerEdge>
        </StyledWidthBasedContainerEdge>
      </StyledWidthBasedContainerEdge>
    </StyledWidthBasedContainerEdge>
  );
};

export default WidthBasedContainer;
