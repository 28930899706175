import React from "react";
import styled from "styled-components";
import {
  DisplayMessage,
  DisplayMessageContentType,
} from "../Interfaces/InputInterfaces";
import Button from "./Button";

interface MessageContentProps {
  message: DisplayMessage;
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContentImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ContentText = styled.div`
  color: ${(props) => props.color};
`;

function downloadFile(base64: string, fileName: string) {
  const content = atob(base64);
  const file = new File([content], fileName);
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(file);
  downloadLink.download = fileName;
  downloadLink.click();
}

const Content = ({
  message: { contentType, content, color, fileName },
}: {
  message: DisplayMessage;
}) => {
  if (contentType === DisplayMessageContentType.Image) {
    return (
      <ContentImage
        src={`data:image/png;base64, ${content}`}
        alt="Message Image"
      />
    );
  }
  if (contentType === DisplayMessageContentType.Text) {
    return <ContentText color={color}>{content}</ContentText>;
  } else {
    return (
      <Button
        label={fileName}
        onClick={() => {
          downloadFile(content, fileName);
        }}
      />
    );
  }
};

const MessageContent: React.FC<MessageContentProps> = ({ message }) => {
  const { align, color, content, contentType } = message;
  console.log("will render");
  console.log(message);
  return (
    <ContentContainer
      style={{ justifyContent: align === -1 ? "flex-start" : "flex-end" }}
    >
      <Content message={message} />
    </ContentContainer>
  );
};

export default MessageContent;
