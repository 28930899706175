import React from "react";
import styled from "styled-components";
import { Activity, List, LogOut } from "react-feather";
import HorizontalSpacing from "./HorizontalSpacing";
import Colors from "../Constants/Colors";
import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";
import { MobileOnly } from "./styledComponents";
import { InfoMessage } from "../Interfaces/InputInterfaces";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.Depth2};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 5rem;
  z-index: 100;
  padding: 0 1rem;

  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Depth3};
    scale: 1.05;
    border-radius: 32px;
  }
`;

const LeftAlignedContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const HeaderText = styled.div`
  color: ${Colors.White};
  font-size: 24px;
  display: flex;
  justify-content: center;
`;

function Header({
  infoMessages,
  showingLogs,
  setShowingLogs,
}: {
  infoMessages: InfoMessage[];
  showingLogs: boolean;
  setShowingLogs: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { logout } = useAuth0();

  return (
    <HeaderContainer>
      <LeftAlignedContent>
        <Logo
          onClick={() => {
            window.location.reload();
          }}
        >
          <Activity style={{ color: Colors.White, strokeWidth: 1 }} size={52} />
        </Logo>
        <HeaderText>Skippy</HeaderText>
      </LeftAlignedContent>
      <MobileOnly>
        <Button
          label={infoMessages.length.toString()}
          onClick={() => {
            setShowingLogs(!showingLogs);
          }}
          icon={<List></List>}
          style={{
            backgroundColor:
              infoMessages.length > 0
                ? Colors[infoMessages[infoMessages.length - 1].type]
                : "",
          }}
        />
      </MobileOnly>
      <Button
        onClick={() => logout()}
        label="Logout"
        icon={<LogOut></LogOut>}
      />
    </HeaderContainer>
  );
}

export default Header;
