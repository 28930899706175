import React from "react";
import { InputRequest } from "../Interfaces/InputInterfaces";
import MessageBox from "./MessageBox";
import Button from "./Button";
import BorderRadius from "../Constants/BorderRadius";

interface ChatInputRequestDisplayProps {
  request: InputRequest;
  inputChosen: (value: string) => void;
}

const ChatInputRequestDisplay: React.FC<ChatInputRequestDisplayProps> = ({
  request,
  inputChosen,
}) => {
  return (
    <>
      {request.info.subType === "image" && (
        <MessageBox align={1} color={request.userResponseInfo.color}>
          <label
            htmlFor="file-upload"
            style={{
              cursor: "pointer",
              borderColor: request.userResponseInfo.color,
              borderWidth: "2px",
              borderStyle: "solid",
              color: request.userResponseInfo.color,
              borderRadius: BorderRadius.Small,
              padding: "0.5rem",
            }}
          >
            Select image
            <input
              id="file-upload"
              style={{ display: "none" }}
              type="file"
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  const file = event.target.files[0];
                  file.arrayBuffer().then((buffer) => {
                    const base64String = btoa(
                      new Uint8Array(buffer).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ""
                      )
                    );
                    inputChosen(base64String);
                  });
                }
              }}
            />
          </label>
        </MessageBox>
      )}
      {request.info.choices && (
        <MessageBox align={1} color={request.userResponseInfo.color}>
          {request.info.choices.map((choice, index) => {
            return (
              <Button
                label={choice.displayValue}
                key={index}
                style={{
                  backgroundColor: "transparent",
                  borderColor: request.userResponseInfo.color,
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: request.userResponseInfo.color,
                }}
                onClick={() => {
                  inputChosen(choice.displayValue);
                }}
              />
            );
          })}
        </MessageBox>
      )}
    </>
  );
};

export default ChatInputRequestDisplay;
