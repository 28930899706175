export interface InputChoice {
  displayValue: string;
}

export interface InputInfo {
  message: string;
  type: string;
  subType: string;
  choices: InputChoice[];
}

export interface DisplayMessage {
  align: -1 | 0 | 1;
  color: string;
  content: string;
  contentType: DisplayMessageContentType;
  fileName: string;
}

export enum DisplayMessageContentType {
  Text,
  Image,
  File,
}

export interface InputRequest {
  info: InputInfo;
  displayMessage: DisplayMessage;
  userResponseInfo: DisplayMessage;
}

export interface InfoMessage {
  type: "success" | "error" | "warning";
  text: string;
}
