import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoBox from "../Shared/InfoBox";
import ChatInput from "../Shared/ChatInput";
import MessageBox from "../Shared/MessageBox";
import MessageList from "../Shared/MessageList";
import WidthBasedContainer from "../Shared/WidthBasedContainer";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useUser } from "../../Providers/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../Shared/Button";
import { LogOut } from "react-feather";
import WavesBackground from "../Shared/WavesBackground";
import HorizontalSpacing from "../Shared/HorizontalSpacing";
import {
  DisplayMessage,
  InfoMessage,
  InputRequest,
} from "../Interfaces/InputInterfaces";
import ChatInputRequestDisplay from "../Shared/ChatInputRequestDisplay";
import TypingAnimation from "../Shared/TypingAnimation";
import MessageContent from "../Shared/MessageContent";
import { DesktopOnly, MobileOnly } from "../Shared/styledComponents";

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ChatContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const Spacer = styled.div`
  min-width: 1rem;
`;

const MessagePage = ({
  infoMessages,
  setInfoMessages,
  showingLogs,
  setShowingLogs,
}: {
  infoMessages: InfoMessage[];
  setInfoMessages: React.Dispatch<React.SetStateAction<InfoMessage[]>>;
  showingLogs: boolean;
  setShowingLogs: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState<DisplayMessage[]>([]);
  const [hubConnection, setHubConnection] = useState<any>(null); // We are using 'any' here, you should use a more specific type for 'hubConnection'.
  const [currentInputRequest, setCurrentInputRequest] =
    useState<InputRequest>();

  const { isAdmin, token, user } = useUser();
  console.log(isAdmin, token, user);

  useEffect(() => {
    // Create a SignalR connection
    const connection = new HubConnectionBuilder()
      //.withUrl("http://localhost:5209/hub?access_token=" + token) // Replace "/hub" with the correct URL of your SignalR hub
      .withUrl("https://sakurapi.se/skippy-backend/hub?access_token=" + token) // Replace "/hub" with the correct URL of your SignalR hub
      .withAutomaticReconnect()
      .build();

    // Set up event handlers for receiving messages
    connection.on("messageReceived", (message: DisplayMessage) => {
      setMessages((prevMessages) => [...prevMessages, message]);

      if (message.align === -1) setIsTyping(false);
    });

    connection.on("requestInput", (request: InputRequest) => {
      setMessages((prevMessages) => [...prevMessages, request.displayMessage]);
      setCurrentInputRequest(request);
      setIsTyping(false);
    });

    connection.on("showTypingAnimation", () => {
      setIsTyping(true);
    });

    connection.on("showInfoMessage", (message: InfoMessage) => {
      setInfoMessages((prevMessages) => [...prevMessages, message]);
    });

    // Start the connection
    connection
      .start()
      .then(() => {
        setInfoMessages((prevMessages) => [
          ...prevMessages,
          { type: "success", text: "Connection established" },
        ]);
        setHubConnection(connection);
      })
      .catch((error) =>
        setInfoMessages((prevMessages) => [
          ...prevMessages,
          { type: "error", text: error.message },
        ])
      );

    return () => {
      // Clean up the connection on component unmount
      if (hubConnection) {
        console.log("SignalR connection stopped");
        hubConnection.stop();
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleNewMessage = (message: string) => {
    if (hubConnection) {
      if (currentInputRequest) {
        // If there is an input request we will send this message as input
        hubConnection.send("SubmitInput", message);
        setCurrentInputRequest(undefined);
      } else {
        // Send the message to the SignalR hub
        hubConnection.send("NewMessage", message);
      }
    }
  };

  return (
    <PageWrapper>
      <WavesBackground>
        <ChatContainer>
          <DesktopOnly>
            <WidthBasedContainer width={"min(20vw, 30rem)"}>
              <MessageList>
                {infoMessages.map((message, index) => (
                  <InfoBox key={index} type={message.type}>
                    {message.text}
                  </InfoBox>
                ))}
              </MessageList>
            </WidthBasedContainer>
          </DesktopOnly>
          <Spacer />
          {!showingLogs && (
            <WidthBasedContainer
              width={"min(90vw, 60rem)"}
              desktopWidth="min(80vw, 60rem)"
            >
              <MessageList maxHeight={"60vh"}>
                {messages.map((message, index) => (
                  <MessageBox
                    animate={true}
                    key={index}
                    color={message.color}
                    align={message.align}
                  >
                    <MessageContent message={message} />
                  </MessageBox>
                ))}
                {currentInputRequest && (
                  <ChatInputRequestDisplay
                    request={currentInputRequest}
                    inputChosen={(input) => {
                      handleNewMessage(input);
                    }}
                  />
                )}
                {isTyping && <TypingAnimation />}
              </MessageList>
              <ChatInput onMessageSubmit={handleNewMessage} />
            </WidthBasedContainer>
          )}
          <MobileOnly>
            {showingLogs && (
              <WidthBasedContainer width={"min(90vw, 60rem)"}>
                <MessageList>
                  {infoMessages.map((message, index) => (
                    <InfoBox key={index} type={message.type}>
                      {message.text}
                    </InfoBox>
                  ))}
                </MessageList>
              </WidthBasedContainer>
            )}
          </MobileOnly>
          <HorizontalSpacing width={1} />
        </ChatContainer>
      </WavesBackground>
    </PageWrapper>
  );
};

export default MessagePage;
