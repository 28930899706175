import React from "react";
import styled, { css, keyframes } from "styled-components";
import Colors from "../Constants/Colors";
import BorderRadius from "../Constants/BorderRadius";

const MessageBoxContainer = styled.div<AlignedMessageBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => {
    if (props.align === -1) return "flex-start";
    if (props.align === 0) return "center";
    if (props.align === 1) return "flex-end";
    return "center"; // Default to left-aligned if no valid alignment value is provided
  }};
  width: 100%;
`;

const AlignedMessageBox = styled.div`
  display: flex;
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0.1;
    transform: scale(1, 1) rotateX(-40deg) translateY(1rem);
  }
  to {
    opacity: 1;
    transform: scale(1, 1) rotateX(0deg) translateY(0rem);
  }
`;

const ColoredMessageBox = styled.div<ColoredMessageBoxProps>`
  padding: 16px;
  border-radius: ${BorderRadius.Default};
  font-size: 16px;
  font-weight: 500;
  white-space: pre-wrap;
  display: flex;
  color: ${(props) =>
    props.color
      ? props.color
      : Colors.White}; // Set text color to the specified color or default to white
  background-color: ${(props) =>
    props.color ? addHintToColor(props.color) : Colors.Depth4};
  margin-bottom: 16px;
  ${(props) => (props.color ? "filter: saturate(100%);" : "")}

  /* ::before pseudo-element for adding a layer of darkness */
    &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); /* Dark layer with 50% opacity */
    border-radius: inherit;
    pointer-events: none; /* Allow interaction with the content behind */
    z-index: -1; /* Behind the box content */
  }

  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3); /* Adjust the shadow values as needed */

  gap: 0.5rem;

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInAnimation} 0.3s linear forwards;
    `}
`;

const addHintToColor = (color: string) => {
  // Add a slight hint to the specified color
  const hintAmount = 18; // You can adjust this value to control the hint intensity
  return `rgba(${getRgb(color)}, ${hintAmount / 100})`;
};

const getRgb = (color: string) => {
  const canvas = document.createElement("canvas");
  canvas.width = canvas.height = 1;
  const ctx = canvas.getContext("2d");
  ctx!.fillStyle = color;
  ctx!.fillRect(0, 0, 1, 1);
  const data = ctx!.getImageData(0, 0, 1, 1).data;
  return [data[0], data[1], data[2]];
};

interface ColoredMessageBoxProps {
  color?: string; // Optional color property
  animate?: boolean; // Whether or not to animate the message box
}

interface AlignedMessageBoxProps {
  align?: -1 | 0 | 1; // Optional alignment property
}

interface MessageBoxProps
  extends AlignedMessageBoxProps,
    ColoredMessageBoxProps {
  children: React.ReactNode;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  children,
  color,
  align,
  animate,
}) => {
  const borderRadiusStyle: React.CSSProperties = {
    borderTopLeftRadius: BorderRadius.Default,
    borderBottomRightRadius: BorderRadius.Default,
  };

  if (align === -1) {
    borderRadiusStyle.borderBottomLeftRadius = 0;
  } else if (align === 1) {
    borderRadiusStyle.borderBottomRightRadius = 0;
  }

  return (
    <MessageBoxContainer align={align}>
      <AlignedMessageBox>
        <ColoredMessageBox
          animate={animate}
          color={color}
          style={{ ...borderRadiusStyle }}
        >
          {children}
        </ColoredMessageBox>
      </AlignedMessageBox>
    </MessageBoxContainer>
  );
};

export default MessageBox;
