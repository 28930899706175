import React from "react";
import styled, { keyframes } from "styled-components";
import Colors from "../Constants/Colors";

const FadeAnimation = keyframes`
  from {
    opacity: 0.5;
    transform: scaleX(0.98);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
`;

const TypingText = styled.div`
  animation: ${FadeAnimation} 0.6s ease-in-out infinite alternate;
  padding: 1rem;
  font-weight: 500;
`;

interface TypingAnimationProps {
  color?: string;
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({ color }) => {
  return (
    <TypingText style={{ color: color ?? Colors.Accent1 }}>
      Typing...
    </TypingText>
  );
};

export default TypingAnimation;
