import { useAuth0, User } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type UserProviderType = {
  token: string | null;
  isAdmin: boolean;
  user: User | undefined;
};

const defaultContext = {
  token: null,
  isAdmin: false,
  user: undefined,
};

const userContext = createContext<UserProviderType>(defaultContext);

export default function UserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [token, setToken] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const {
    user,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<{ permissions?: string[] }>(token);
      if (decoded.permissions) {
        setIsAdmin(decoded.permissions.includes("admin"));
      }
    }
  }, [token]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }
    if (isAuthenticated) {
      getAccessTokenSilently().then(setToken);
    }
    if (error) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [isAuthenticated, isLoading, error]);

  const value = useMemo(
    () => ({
      token,
      isAdmin,
      user,
    }),
    [token, isAdmin, user]
  );

  if (!token) return null;

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
}

export function useUser() {
  return useContext(userContext);
}
