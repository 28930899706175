import React from "react";
import styled, { css } from "styled-components";
import Colors from "../Constants/Colors"; // Assuming the Colors.tsx file is in the same folder

interface InfoBoxProps {
  type: "success" | "error" | "warning";
  children: React.ReactNode;
}

const InfoBoxContainer = styled.div<InfoBoxProps>`
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  /* Define styles for different message types */
  ${(props) => getInfoBoxTypeStyles(props.type)}
`;

const getInfoBoxTypeStyles = (type: "success" | "error" | "warning") => css`
  background-color: ${Colors[type]};
  color: ${Colors.Depth3};
`;

const InfoBoxIcon = styled.span`
  margin-right: 12px;
`;

const InfoBox: React.FC<InfoBoxProps> = ({ type, children }) => {
  let icon;

  switch (type) {
    case "success":
      icon = "✔️";
      break;
    case "error":
      icon = "❌";
      break;
    case "warning":
      icon = "⚠️";
      break;
    default:
      icon = "";
  }

  return (
    <InfoBoxContainer type={type}>
      {icon && <InfoBoxIcon>{icon}</InfoBoxIcon>}
      {children}
    </InfoBoxContainer>
  );
};

export default InfoBox;
