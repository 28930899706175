const Colors = {
  Depth1: "#23232a",
  Depth2: "#2a2a32",
  Depth3: "#31313B",
  Depth4: "#3c3c48",
  Depth5: "#464653",
  Depth6: "#50505e",
  Depth7: "#5a5a69",
  Depth8: "#656576",
  Depth9: "#717184", // Brightest depth
  Accent1: "#9777c7",
  Accent2: "#8e8e97",
  Accent3: "#007BFF",
  success: "#4eb867",
  error: "#e8695f",
  warning: "#e3ca76",
  DarkBlue: "#0f0e1b",
  Wine: "#ede4da",
  White: "#eeeeee",
};

export default Colors;
