import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 230px);
  margin-top: 5rem;
  @media (min-width: 768px) {
    min-height: unset;
    margin-top: 0;
  }
`;

interface MessageListProps {
  children: React.ReactNode;
  maxHeight?: string; // Optional maxHeight property
}

const MessageList: React.FC<MessageListProps> = ({ children, maxHeight }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [children]);

  return (
    <MessageListContainer
      ref={containerRef}
      style={{ maxHeight: maxHeight || "none" }}
    >
      {children}
    </MessageListContainer>
  );
};

export default MessageList;
