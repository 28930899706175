import React, { useState, FormEvent } from "react";
import styled from "styled-components";
import Colors from "../Constants/Colors";
import BorderRadius from "../Constants/BorderRadius";

const Form = styled.form`
  width: 100%;
  margin-top: auto;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column; /* Arrange child elements vertically */
  padding: 8px;
  border-radius: ${BorderRadius.Default};
  background-color: ${Colors.Depth3};
  font-family: "Jost";
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
`;

const InputField = styled.textarea`
  flex-grow: 1; /* Take up all available vertical space */
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px;
  background-color: ${Colors.Depth3};
  color: ${Colors.White};
  font-family: "Jost";
  resize: none; /* Prevent resizing */
  overflow-y: auto; /* Add vertical scrollbar if the content overflows */
`;

const SendButton = styled.button`
  background-color: ${Colors.Accent2};
  color: ${Colors.Depth1};
  border: none;
  outline: none;
  padding: 8px 16px;
  border-radius: ${BorderRadius.Small};
  cursor: pointer;
  margin-top: 8px; /* Add some space between the textarea and the button */
  font-family: "Jost";

  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
`;

interface ChatInputProps {
  onMessageSubmit: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onMessageSubmit }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (message.trim() !== "") {
      onMessageSubmit(message);
      setMessage("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Submit on Enter without Shift (event.key === "Enter" is the modern standard)
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event as any); // We need to cast event to `any` because the type definition for FormEvent does not include `key` property
    }
    // Insert line break on Shift + Enter
    else if (event.key === "Enter" && event.shiftKey) {
      setMessage((prevMessage) => prevMessage + "\n");
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <InputContainer>
        <InputField
          rows={1} // Initially one row, will expand as the user types multiline content
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SendButton type="submit">Send</SendButton>
      </InputContainer>
    </Form>
  );
};

export default ChatInput;
