import React from "react";
import styled from "styled-components";
import wavesBackground01 from "../../Assets/waves-background-01-01.svg";
import wavesBackground02 from "../../Assets/waves-background-01-02.svg";
import wavesBackground03 from "../../Assets/waves-background-01-03.svg";

const BackgroundContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url(${wavesBackground03});
  background-position: bottom;
  background-color: rgba(255, 255, 255, 0.2);
`;

interface WavesBackgroundProps {
  children: React.ReactNode;
}

const WavesBackground: React.FC<WavesBackgroundProps> = ({ children }) => {
  return <BackgroundContainer>{children}</BackgroundContainer>;
};

export default WavesBackground;
