import { styled } from "styled-components";

export const MobileOnly = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;