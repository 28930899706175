import "./App.css";
import MessagePage from "./Components/Pages/MessagePage";
import { Auth0Provider } from "@auth0/auth0-react";
import UserProvider from "./Providers/UserProvider";
import Header from "./Components/Shared/Header";
import { useState } from "react";
import { InfoMessage } from "./Components/Interfaces/InputInterfaces";

const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";

function App() {
  const [infoMessages, setInfoMessages] = useState<InfoMessage[]>([]);
  const [showingLogs, setShowingLogs] = useState<boolean>(false);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
    >
      <UserProvider>
        <Header
          infoMessages={infoMessages}
          showingLogs={showingLogs}
          setShowingLogs={setShowingLogs}
        />
        <MessagePage
          infoMessages={infoMessages}
          setInfoMessages={setInfoMessages}
          showingLogs={showingLogs}
          setShowingLogs={setShowingLogs}
        />
      </UserProvider>
    </Auth0Provider>
  );
}

export default App;
